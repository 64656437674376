<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="货源编号"
            ><el-input
              placeholder="请输入关键词"
              v-model.trim="conditions.pno"
              clearable
            >
            </el-input
          ></el-form-item>
          <el-form-item label="运单编号"
            ><el-input
              placeholder="请输入关键词"
              v-model.trim="conditions.transportBillCode"
              clearable
            >
            </el-input
          ></el-form-item>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-dropdown
              trigger="click"
              style="margin-right: 10px"
              @command="handleCommand"
            >
              <el-button type="primary">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">全部导出</el-dropdown-item>
                <el-dropdown-item command="b">批量导出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button type="primary" class="buttons" @click="handleAdd"
              >新增合同</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table
          border
          :data="tableData"
          show-summary
          :summary-method="getSummaries"
          ref="tableRef"
          row-key="id"
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            width="55"
            reserve-selection
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货源编号"
            prop="pno"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="运单编号"
            prop="transportCode"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="车队名称"
            prop="fleetName"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="车牌号"
            prop="plate"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="司机名称"
            prop="driverName"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="审核时间"
            prop="checkedtime"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="回皮吨数"
            prop="tare"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="装车吨数"
            prop="suttle"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="过重吨数"
            prop="roughWeight"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="过重时间"
            prop="mwitemdate"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="出场时间"
            prop="outstoredate"
          ></el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
        <AddContacts
          v-if="showAdd"
          ref="addContactsRef"
          @confirm="handleConfirm"
          @cancel="handleCancel"
        ></AddContacts>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
export default {
  data() {
    return {
      enumStatus: {
        0: { label: "待审核", type: "" },
        1: { label: "正常", type: "success" },
        2: { label: "审核未通过", type: "danger" },
        3: { label: "停用", type: "info" },
      },
      conditions: {
        page: 1,
        limit: 15,
      },
      total: 0,
      statTotal: 0,
      statSum: 0,
      tableData: [],
      showAdd: false,
    };
  },
  // computed: {
  //   ...mapState(["conditionsList"]),
  // },
  components: {
    Pagination,
  },
  created() {
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/api/stat/jobDetail",
        "get",
        this.conditions
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableData = data.data.list;
      this.total = data.data.totalCount;
      this.statTotal = data.stat.total;
      this.statSum = data.stat.sumSuttle;
    },
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = "合计车次";
          return;
        } else if (index === 2) {
          sums[index] = this.statTotal;
          return;
        } else if (index === 6) {
          sums[index] = "合计净重";
          return;
        } else if (index === 7) {
          sums[index] = this.statSum;
          return;
        } else sums[index] = "";
      });
      return sums;
    },
    async handleCommand(command) {
      if (command == "a") {
        this.$download(
          "post",
          "/api/stat/jobDetailExport",
          this.conditions
        );
      } else if (command == "b") {
        if (!this.checkedIds || this.checkedIds.length == 0) {
          return this.$message.error("请选择要导出的数据");
        }
        this.$download("post", "/api/stat/jobDetailExport", {
          ids: this.checkedIds.toString(),
        });
      }
    },
    handleSelectionChange(val) {
      this.checkedIds = val.map((item) => item.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
